<!-- Topbar Start -->
<div class="container-fluid bg-networkcode bg-light px-0 wow fadeIn" data-wow-delay="0.1s">
  <div class="row gx-0 align-items-center d-none d-lg-flex">
    <div class="col-lg-6 px-5 text-start">
      <!--    <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><a class="small text-secondary" href="#">Home</a></li>
                <li class="breadcrumb-item"><a class="small text-secondary" href="#">Career</a></li>
                <li class="breadcrumb-item"><a class="small text-secondary" href="#">Terms</a></li>
                <li class="breadcrumb-item"><a class="small text-secondary" href="#">Privacy</a></li>
            </ol>  -->
    </div>
    <div class="col-lg-6 px-5 text-end">
      <small>Follow us:</small>
      <div class="h-100 d-inline-flex align-items-center">
        <!-- <a class="btn-square text-primary border-end rounded-0" href=""><i class="fab fa-facebook-f"></i></a>
        <a class="btn-square text-primary border-end rounded-0" href=""><i class="fab fa-twitter"></i></a> 
        <a class="btn-square text-primary pe-0" href=""><i class="fab fa-instagram"></i></a> -->
        <a class="btn-square text-primary border-end rounded-0" href="https://www.linkedin.com/company/netcode-tech/"><i class="fab fa-linkedin-in icon-small"></i></a>
        
      </div>
    </div>
  </div>
</div>


<div class="container-fluid py-4 px-5 wow fadeIn" data-wow-delay="0.1s">
  <div class="row align-items-center top-bar">
    <div class="col-lg-4 col-md-12 text-center text-lg-start">
      <a href="/home" class="navbar-brand m-0 p-0"><img src="assets/logo.png" class="img-header" alt="Logo" /></a>
    </div>
    <div class="col-lg-8 col-md-7 d-none d-lg-block">
      <div class="row">
        <div class="col-6">
          <div class="d-flex align-items-center justify-content-end">
            <div class="flex-shrink-0 btn-lg-square border rounded-circle">
              <i class="far fa-clock text-primary icon-small"></i>
            </div>
            <div class="ps-3">
              <p class="mb-2">Horas Apertura</p>
              <h6 class="mb-0">Lunes - Viernes</h6>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-center justify-content-end">
            <div class="flex-shrink-0 btn-lg-square border rounded-circle">
              <i class="far fa-envelope text-primary icon-small"></i>
            </div>
            <div class="ps-3">
              <p class="mb-2">Email Us</p>
              <a href="/home/contactar" class="btn-link">Visitar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<nav class="navbar navbar-expand-lg bg-networkcode navbar-dark sticky-top py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
  <a href="#" class="navbar-brand ms-3 d-lg-none">MENU</a>
  <button type="button" class="navbar-toggler me-3" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"><span class="navbar-toggler-icon"></span></button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <div class="navbar-nav me-auto p-3 p-lg-0">
      <a href="/home" class="nav-item nav-link">Home</a>
      <a href="/home/proyectos" class="nav-item nav-link">Proyectos</a>
      <div class="nav-item dropdown">
        <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Servicios</a>
        <div class="dropdown-menu border-0 rounded-0 rounded-bottom m-0">
          <a href="/home/conectividad" class="dropdown-item">Servicios de Conectividad - Internet</a>
          <!-- <a href="/home/dominios" class="dropdown-item">Dominios</a> -->
          <a href="/home/email" class="dropdown-item">Servicio de Email</a>
          <!-- <a href="/home/vps" class="dropdown-item">Servicio de VPS</a> -->
          <a href="/home/backup" class="dropdown-item">Copias de Seguridad</a>
        </div>
      </div>
      <a href="/home/contactar" class="nav-item nav-link">Contactar</a>
    </div>
    <!-- <div class="btn-group" role="group" aria-label="Basic example">
      <a href="/home/registro" class="btn btn-sm btn-info py-2 px-4 d-none d-lg-block">Registrarse</a>
      <a href="/login" class="btn btn-sm btn-light py-2 px-4 d-none d-lg-block">Iniciar Sesion</a>
    </div> -->
  </div>
</nav>
