<div class="container py-5">
  <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
    <h6 class="section-title bg-white text-center text-primary px-3">Nuestras Metodologia</h6>
    <h1 class="display-6 mb-4">Descubre nuestra metodología</h1>
  </div>
  <div class="row g-4">
    <div class="col-md-3">
      <div class="card step-card">
        <div class="card-body text-center">
          <div class="step-icon mx-auto">1</div>
          <h5 class="card-title">Planificacion y presupuestación</h5>
          <p class="card-text">Se analiza el proyecto y se hablan comentando las funciones a realizar. Se acuerda una tarifa adaptada al proyecto y se realizan los documentos pertinentes.</p>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="card step-card">
        <div class="card-body text-center">
          <div class="step-icon mx-auto">2</div>
          <h5 class="card-title">Desarrollo e implementación</h5>
          <p class="card-text">Se programa desde cero la aplicación y se va realizando una monitarizacion del proceso.</p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card step-card">
        <div class="card-body text-center">
          <div class="step-icon mx-auto">3</div>
          <h5 class="card-title">Pruebas</h5>
          <p class="card-text">Se realizan las pruebas de caja blanca y caja negra al igual que las pruebas unitarias pertinentes.</p>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card step-card">
        <div class="card-body text-center">
          <div class="step-icon mx-auto">4</div>
          <h5 class="card-title">Lanzamiento y mantenimiento</h5>
          <p class="card-text">Se configura el proyecto y se efectua la puesta en produccion o se divide en los entornos marcados por el cliente.</p>
        </div>
      </div>
    </div>
  </div>

</div>
