<!-- Footer Start -->
<div class="container-fluid bg-dark text-body footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
    <div class="container py-5">
        <div class="row g-5">
            <div class="col-lg-3 col-md-6">
                <!--<h5 class="text-light mb-4">Address</h5>
                <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>123 Street, New York, USA</p>
                <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+012 345 67890</p>
                 <p class="mb-2"><i class="fa fa-envelope me-3"></i>info@example.com</p> -->
                <div class="d-flex pt-2">
                    <!--<a class="btn btn-square btn-outline-secondary rounded-circle me-1" href=""><i class="fab fa-twitter"></i></a> -->
                    <a class="btn btn-square btn-outline-secondary rounded-circle me-1" href="https://www.facebook.com/ntwcode/"><i class="fab fa-facebook-f"></i></a>
                    <!-- <a class="btn btn-square btn-outline-secondary rounded-circle me-1" href=""><i class="fab fa-youtube"></i></a> -->
                    <a class="btn btn-square btn-outline-secondary rounded-circle me-0" href="https://www.linkedin.com/company/netcode-tech/"><i class="fab fa-linkedin-in"></i></a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <h5 class="text-light mb-4">Enlaces Directos</h5>
                <a class="btn btn-link" href="/">Sobre  Nosotros</a>
                <a class="btn btn-link" href="/home/contactar">Contactar</a>
                <a class="btn btn-link" href="/home/conectividad">Nuestros Servicios</a>
                <a class="btn btn-link" href="/home/terminos">Terminos y Condiciones</a>
            </div>
            <div class="col-lg-3 col-md-6">
                <h5 class="text-light mb-4">Otros Servicios</h5>
                <a class="btn btn-link" target="_blank" href="https://www.teleca.es/">Tienda informatica</a>
                <!-- <a class="btn btn-link" target="_blank" href="https://www.linkedforjobs.com/">Portal de Empleo</a> -->
            </div>
            
        </div>
    </div>
    <div class="container-fluid copyright">
        <div class="container">
            <div class="row">
                <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                    &copy; <a href="#">Networkcode Tech Solution SL</a>, All Right Reserved.
                </div>
                <div class="col-md-6 text-center text-md-end">
                    <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
                    Designed By <a href="https://htmlcodex.com">HTML Codex</a>
                    <br>Distributed By: <a class="border-bottom" href="https://themewagon.com" target="_blank">ThemeWagon</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Footer End -->
