import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CardsComponent } from './cards/cards.component';
import { TeamComponent } from './team/team.component';
import { ProjectsComponent } from './projects/projects.component';
import { StepsComponent } from './steps/steps.component';
import { StructureDataComponent } from './structure-data/structure-data.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    CardsComponent,
    TeamComponent,
    ProjectsComponent,
    StepsComponent,
    StructureDataComponent
  ],
  exports:[
    HeaderComponent,
    FooterComponent,
    CardsComponent,
    TeamComponent,
    ProjectsComponent,
    StepsComponent,
    StructureDataComponent
  ]
})
export class ComponentesModule { }
